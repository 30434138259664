.not-found-page {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f8f9fa;
}

h1 {
  font-size: 6rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

p {
  font-size: 1.5rem;
  margin-bottom: 2rem;
}

.btn-primary {
  background-color: #fbc91a;
  border-color: #fbc91a;
}

.btn-primary:hover {
  background-color: #fbc91a;
  border-color: #fbc91a;
}
