.navy {
  z-index: 1000;
  box-shadow: inset 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
}

.nav-link {
  cursor: pointer;
}

.nav-link.header-link.active {
  color: var(--blue-color);
}

.nav-link.header-link:hover {
  color: var(--blue-color);
}

.navy-black {
  background-color: rgba(3, 3, 3, 0.849);
}

.navy-black .nav-link {
  color: rgb(255, 255, 255);
  font-size: 1.3rem;
}

.toggle-button {
  position: absolute;
  top: 45px;
  right: 45px;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 21px;
}
.bar1,
.bar2,
.bar3 {
  height: 3px;
  width: 100%;
  background-color: gray;
  border-radius: 10px;
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.drop-menu-header {
  font-size: 1.3rem;
}

.drop-menu-header {
  margin-top: 8px;
}

.nav-drop-toggle {
  color: azure;
  cursor: pointer;
}

.nav-drop-toggle:hover {
  color: var(--blue-color);
}

.drop-menu-nav {
  background-color: rgb(0, 0, 0);
  height: 57vh;
  overflow-y: scroll;
}

.drop-menu-nav::-webkit-scrollbar {
  -webkit-appearance: none;
  width: 7px;
}

.drop-menu-nav::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background-color: rgba(255, 255, 255, 0.5);
  -webkit-box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
}

@media (max-width: 992px) {
  .toggle-button {
    display: flex;
    transition: all 0.5s ease-in-out;
  }

  .navbar-header {
    position: absolute;
    bottom: -80vh;
    min-height: 80vh;
    width: 80vw;
    background-color: #363333;
    left: -80vw;
    transition: all 0.5s ease-in-out;
  }

  .nav-drop-toggle {
    background-color: #363333;
  }

  .nav-link.nav-link.header-link,
  .nav-drop-toggle {
    padding: 15px;
    color: #000000;
    text-transform: uppercase;
    font-weight: 700;
  }

  .toggle-button.open .bar2 {
    transform: translateX(-50px);
    background: transparent;
    box-shadow: none;
  }

  .toggle-button.open .bar1 {
    transform: rotate(45deg) translate(0px, 13px);
    background-color: gray;
  }
  .toggle-button.open .bar3 {
    transform: rotate(-45deg) translate(0px, -13px);
    background-color: gray;
  }

  .navbar-header.open {
    left: 0px;
    transition: all 0.5s ease-in-out;
  }

  .dropdown-menu.show {
    position: absolute;
    width: 80vw;
  }

  .drop-menu-nav .nav-link.header-link {
    color: black;
  }

  .drop-menu-header {
    margin-bottom: 8px;
  }
}
