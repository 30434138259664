.hero {
  /* margin-top: -80px; */
  min-height: 100vh;
}

.caro-img {
  height: calc(100vh + 80px);
  object-fit: cover;
}

.caro-container {
  position: relative;
}

.caro-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(245, 241, 241, 0.1); /* adjust the alpha value to change the darkness */
  z-index: 1;
}

.carousel-indicators {
  display: none;
}

.caro-text {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  bottom: unset;
  right: unset;
  z-index: 4;
  width: 60%;
}
.custom-button {
  color: #fbc91a; /* Text color */
  border-color: #fbc91a; /* Border color */
}

.custom-button:hover {
  background-color: #fbc91a; /* Background on hover */
  color: #000000; /* Optional: Change text color on hover */
}
