.careers-page {
  font-family: "Arial", sans-serif;
}

.welcome-header {
  font-size: 2.5rem;
  font-weight: bold;
  color: #fbc91a;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
}

.benefits-container {
  background: linear-gradient(145deg, #fbc91a, #fbc91a);
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.08);
}

.benefits-list {
  color: rgb(0, 0, 0);
  font-size: 1.1rem;
  list-style-type: none;
  margin-bottom: 1.5rem;
}

.benefits-list li {
  margin-bottom: 0.8rem;
}

.benefits-list li::before {
  content: "\2713";
  display: inline-block;
  margin-right: 0.5rem;
  font-size: 1.2rem;
}

.benefits-container p {
  color: rgb(0, 0, 0);
  font-size: 1.1rem;
  line-height: 1.5;
}
