.landing-about {
  min-height: 80vh;
  background-color: #1a1a1a; /* Dark background */
  color: #f6f6f5; /* Light text for contrast */
}

.landing-row {
  min-height: 80vh;
}

.landing-line {
  height: 3px;
  width: 80px;
  border: none;
  background-color: #fbc91a; /* Highlight color for lines */
}

.text-color {
  color: #f6f6f5; /* Light text for readability */
}

.highlight-color {
  color: #fbc91a; /* Bright accent color */
}

.icon-color {
  color: #fbc91a; /* Icon color matches the theme */
}

.dark-mode h3,
.dark-mode p {
  color: #f6f6f5; /* Ensure headings and paragraphs are readable */
}
