.appointments {
  min-height: 90vh;
  background-image: url("https://images.unsplash.com/photo-1606413446724-b6d1ceba8e4c?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mjl8fHNlY3VyaXR5JTIwZ3VhcmR8ZW58MHwwfDB8fA%3D%3D&auto=format&fit=crop&w=700&q=60");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  background-attachment: fixed;
}

.appointments::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fbca1a84; /* Dark overlay for better contrast */
  z-index: 1;
}


.appointments h3 {
  position: relative;
  z-index: 3;
  color: #f6f6f5; /* Light color for headings */
  transform: translate(0%, 55%);
}

.apt-form {
  position: relative;
  z-index: 3;
  transform: translate(0, 50%);
  color: #f6f6f5;
}

.form-control-dark {
  background-color: #333333; /* Dark background for input fields */
  color: #f6f6f5; /* Light text for input fields */
  border: 1px solid #555555; /* Subtle border for input fields */
}

.form-control-dark::placeholder {
  color: #b0b0b0; /* Light gray for placeholder text */
}

.form-control-dark:focus {
  background-color: #444444; /* Slightly lighter background on focus */
  border-color: #070707; /* Highlight border on focus */
  color: #ffffff;
}

button.btn-outline-warning {
  background-color: #fbc91a;
  color: #ffffff;
}

button.btn-outline-warning:hover {
  background-color: #fbc91a;
  color: #040404;
}

@media (max-width: 552px) {
  .appointments {
    min-height: 70vh;
  }
}
