.security-services {
  text-align: center;
  background-color: #1a1a1a; /* Dark background */
  color: #f6f6f5; /* Light text for contrast */
  box-shadow: 0 5px 10px rgba(255, 255, 255, 0.1); /* Softer shadow */
  position: relative;
}

.subtitle {
  color: #b0b0b0; /* Light grey for subtitles */
  font-size: 1.5rem;
  margin: 1rem 0 0.5rem;
}

.title {
  color: #ffffff; /* White for main titles */
  font-size: 3rem;
  margin: 0;
}

.square {
  position: relative;
  z-index: 4;
  margin: 0 auto;
  width: 10px;
  height: 10px;
  border: 1px solid #f6f6f5; /* Light border for visibility */
  background-color: #1a1a1a; /* Matches the dark background */
}

hr.service-line {
  border: none;
  height: 2px;
  background-color: #fbc91a; /* Bright highlight color */
  margin: 0 auto;
  width: 60px;
  margin-top: -6px;
  margin-bottom: 80px;
}

.border-right {
  border-right: 1px solid rgba(255, 255, 255, 0.3); /* Light border for contrast */
}

.icon {
  color: #f6f6f5; /* Default icon color */
}

.icon:hover {
  color: #fbc91a; /* Highlight color on hover */
}

.hover:hover {
  background-color: rgba(255, 255, 255, 0.1); /* Light hover effect */
  transition: background-color 0.3s ease;
}

.blue-color {
  color: #fbc91a; /* Highlight color for active selection */
}

.dark-colour {
  color: #b0b0b0; /* Subdued color for inactive states */
}

.security-services::before {
  content: "";
  position: absolute;
  bottom: -20px;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 0;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-top: 20px solid #1a1a1a; /* Matches dark background */
}

@media (max-width: 768px) {
  .mobile-cctv-service {
    border-right: none;
  }
}
 