/* JobApplication.css - Dark Mode */
.job-application-form {
  background-color: #1e1e1e; /* Dark background */
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5); /* Stronger shadow */
  padding: 2rem;
  color: #f1f1f1; /* Light text color */
}

.job-application-container button[type="submit"] {
  background-color: #eec53e;
  border: none;
  color: #1e1e1e; /* Dark text on button */
  font-weight: bold;
  padding: 0.5rem 1rem;
  transition: background-color 0.3s;
}

.job-application-container button[type="submit"]:hover {
  background-color: #ccbf2c;
}

.job-application-container input[type="text"],
.job-application-container input[type="email"],
.job-application-container input[type="tel"],
.job-application-container textarea,
.job-application-container select {
  border-radius: 5px;
  border: 1px solid #444444; /* Darker border */
  background-color: #2c2c2c; /* Dark input background */
  color: #f1f1f1; /* Light text */
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.job-application-container input[type="text"]:focus,
.job-application-container input[type="email"]:focus,
.job-application-container input[type="tel"]:focus,
.job-application-container textarea:focus,
.job-application-container select:focus {
  border-color: #eec53e; /* Yellow highlight on focus */
  box-shadow: 0 0 0 0.2rem rgba(238, 197, 62, 0.5); /* Yellow glow */
}
