.footer {
  background-image: url("https://images.unsplash.com/photo-1523294557-3637e1db3f33?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxzZWFyY2h8NHx8c2VjdXJpdHklMjBndWFyZHxlbnwwfDB8MHx8&auto=format&fit=crop&w=800&q=60");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;
  padding: 50px 20px; /* Added padding for better spacing */
}

.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7); /* Dark overlay */
  z-index: 1;
}

.footer-text {
  position: relative;
  color: white;
  z-index: 2; /* Text above overlay */
  text-align: center;
}

.footer-text h3 {
  letter-spacing: 4px;
  margin-bottom: 10px;
}

.footer-btn {
  width: 120px; /* Adjusted button width */
  padding: 10px;
  cursor: pointer;
  text-align: center;
  display: inline-block;
  margin: 10px 5px;
}

.footer-btn.active {
  background-color: #192e40;
  border-bottom: 3px solid #ffab00;
  color: white;
}

.footer-btn.not-active {
  background-color: transparent;
  border-bottom: 3px solid #555555;
  color: #555555;
}

.footer-btn:hover {
  color: white;
  border-bottom: 3px solid #ffab00;
}

.footer-msg {
  margin-top: 50px; /* Reduced margin for better layout */
}

.icon-container {
  border-radius: 50%;
  padding: 15px; /* Reduced padding */
  background-color: #192e40;
  display: inline-block;
  margin-bottom: 15px; /* Added spacing below icons */
}

.contact-info {
  margin-top: 50px;
  z-index: 2; /* Ensures content is above the background overlay */
}

.contact-info h4 {
  margin-top: 10px;
  margin-bottom: 10px;
  letter-spacing: 1px;
}

.contact-info p {
  margin: 0 0 10px;
  line-height: 1.6;
  color: white;
}

.footer-form {
  margin-top: 50px;
}

.name-field,
.phone-field,
.email-field,
.reason-field,
.message-field {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid #555555;
  margin-bottom: 20px;
  border-radius: 0;
  color: white; /* Ensure text is visible */
}

.name-field:focus,
.phone-field:focus,
.email-field:focus,
.reason-field:focus,
.message-field:focus {
  border-bottom: 1px solid #ffab00; /* Highlight focus */
  outline: none;
}

@media (max-width: 768px) {
  .footer-col {
    margin-bottom: 30px; /* Reduced margin for smaller screens */
    text-align: center; /* Center align columns */
  }

  .contact-info {
    margin-top: 30px;
  }

  .icon-container {
    padding: 10px; /* Smaller icons on mobile */
  }
}
.icon-container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 60px; /* Uniform width and height */
  height: 60px;
  border-radius: 50%;
  background-color: #fbc91a; /* Yellow background */
  margin-bottom: 15px;
}
.facebook-icon {
  display: inline-block;
  
  border-radius: 50%; /* Circular shape */
  padding: 15px; /* Adjust padding for proportions */
  font-size: 36px; /* Adjust icon size */
  color: yellow; /* Yellow icon color */
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  line-height: 1; /* Prevent stretching of content */
}

.facebook-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 10px rgba(255, 255, 0, 0.3); /* Yellow glow on hover */
  color: #ffd700; /* Slightly brighter yellow on hover */
}
