.service-page {
 
  padding: 50px;
  color: #f5f5f5; /* Light text for readability */
}

.service-page h1 {
  font-weight: bold;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7); /* Subtle shadow for depth */
  color: #ffffff; /* Bright white for emphasis */
}

.service-page .long-description p {
  font-size: 1.1rem;
  line-height: 1.8;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.6); /* Dark shadow */
  color: #e0e0e0; /* Softer light grey for paragraphs */
}

.service-page .carousel img {
  height: 400px;
  object-fit: cover;
  border-radius: 5px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.8), 0 1px 3px rgba(0, 0, 0, 0.6); /* Dark shadows */
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.service-page .carousel img:hover {
  transform: scale(1.05); /* Subtle zoom on hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 1), 0 2px 4px rgba(0, 0, 0, 0.8); /* Enhanced shadow */
}

.service-page .carousel .carousel-control-next-icon,
.service-page .carousel .carousel-control-prev-icon {
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.7)); /* Darker shadow for visibility */
}

.cta-container {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}

.cta-button {
  display: inline-block;
  background-color: #333333; /* Neutral dark background for buttons */
  color: #ffffff; /* Bright text for contrast */
  text-decoration: none;
  padding: 12px 24px;
  border-radius: 5px;
  font-size: 1.1rem;
  font-weight: bold;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.8), 0 1px 3px rgba(0, 0, 0, 0.6); /* Darker shadows */
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.cta-button:hover {
  background-color: #444444; /* Slightly lighter grey for hover */
  box-shadow: 0 6px 8px rgba(0, 0, 0, 1), 0 2px 4px rgba(0, 0, 0, 0.8); /* Increased intensity on hover */
}

@media (max-width: 768px) {
  .service-page {
    padding: 30px; /* Adjust padding for smaller screens */
  }

  .service-page h1 {
    font-size: 2rem; /* Reduce heading size for smaller screens */
  }

  .service-page .long-description p {
    font-size: 1rem; /* Smaller font for mobile */
  }

  .service-page .carousel img {
    height: 300px; /* Adjust carousel image height */
  }
}
