/* index.css */

/* Base styles */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  background-color: var(--dark-bg-color); /* Dark mode background */
  color: var(--dark-text-color); /* Dark mode text color */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  text-decoration: none;
  color: var(--dark-link-color); /* Dark mode link color */
}

hr {
  opacity: 1;
  background-color: var(--dark-text-color); /* Match dark text */
}

/* Root variables */
:root {
  --blue-color: #fbc91a;
  --dark-color: #000000; /* Text color for light mode */
  --cream-color: #ffffff; /* Background color for light mode */
  --dark-bg-color:var(--dark-color)

}

/* Fonts */
.signika {
  font-family: "Signika", sans-serif;
}

.oxygen {
  font-family: "Oxygen", sans-serif;
}

/* Utility classes */
.blue-color {
  color: var(--blue-color);
}

.dark-color {
  color: var(--dark-text-color); /* Ensure consistency */
}

.cream-bg {
  background-color: var(--dark-bg-color); /* Replace light background */
}

.hover {
  cursor: pointer;
}

