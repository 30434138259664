.display-service-img {
  height: 350px;
  width: 90%;
  object-fit: cover;
  border-radius: 10px;
}
.cream-bg {
  background-color: #1a1a1a; /* Dark background for dark mode */
  color: #f6f6f5; /* Light text color for readability */
}

.display-service-img {
  width: 100%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(255, 255, 255, 0.1), 0 1px 3px rgba(255, 255, 255, 0.08); /* Subtle shadow for images */
}

h3 {
  color: #ffffff; /* White color for headings */
  font-weight: bold;
  text-transform: uppercase;
}

p {
  color: #d1d1d1; /* Softer light grey for paragraphs */
  font-size: 1.1rem;
  line-height: 1.7; /* Improved line spacing for better readability */
}

@media (max-width: 768px) {
  h3 {
    font-size: 1.5rem; /* Adjust heading size for mobile */
  }

  p {
    font-size: 1rem; /* Slightly smaller text for mobile */
  }

  .display-service-img {
    height: 250px; /* Adjust image size for mobile */
  }
}
